<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">清运记录</h1>
    </div>
    <div slot="extra">
      <a-button
        style="margin-left: 16px;"
        icon="plus"
        @click="exportWord"
        type="primary"
      >导出
      </a-button>
    </div>
    <a-card :bordered="false" style="margin-top: 15px;">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="执行司机">
              <a-tree-select
                style="width: 150px;"
                allowClear
                :treeData="userList"
                v-model="executeUserStr"
                @change="beforeSearch"
                placeholder="请选择执行司机">
              </a-tree-select>
            </a-form-item>
            <a-form-item label="执行车辆">
              <a-select
                style="width: 150px;"
                allowClear
                showSearch
                optionFilterProp="label"
                v-model="searchParams.carInfoId"
                placeholder="请选择执行车辆"
                @change="search">
                <a-select-option v-for="item in carList" :key="item.id" :value="item.id" :label="item.name">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="执行时间">
              <a-range-picker @change="dateOnChange" v-model="dateSearch">
                <a-icon slot="suffixIcon" type="smile"/>
              </a-range-picker>
            </a-form-item>
            <a-form-item label="任务状态">
              <a-radio-group @change="selectStatus" v-model="searchParams.status">
                <a-radio-button value="">全部</a-radio-button>
                <a-radio-button value="NOT">未开始</a-radio-button>
                <a-radio-button value="DOING">进行中</a-radio-button>
                <a-radio-button value="COMPLETED">已完成</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
        :pagination="pagination"
        @change="tableChange"
        :customRow="rowClick"
        :rowKey="(record) => record.id"
        bordered
        :scroll="{ x: 1700 }">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="recordNames" slot-scope="text, record">
          <a-tag v-for="(item,index) in record.recordNames" :key="index" color="#108ee9" style="margin: 3px;">{{item}}</a-tag>
        </template>
        <template slot="status" slot-scope="text, record">
          <a-tag v-if="record.status === 'NOT'">未开始</a-tag>
          <a-tag v-if="record.status === 'DOING'" color="#87d068">进行中</a-tag>
          <a-tag v-if="record.status === 'COMPLETED'" color="#108ee9">已完成</a-tag>
        </template>
        <template slot="executeTime" slot-scope="text, record">
          <span>{{ record.startTime }} ~ {{ record.endTime }}</span>
        </template>
        <!--<template slot="action" slot-scope="text, record">
          <a v-if="record.status === 'COMPLETED'" @click.stop="handleEdit(record.id)">编辑</a>
          <a v-else style="color: grey">进行中</a>
        </template>-->
      </a-table>
    </a-card>
    <!--修改清运记录-->
    <a-modal
      title="修改清运记录"
      :visible="editModal"
      @ok="handleEditOk"
      @cancel="closeEditModal"
      :confirmLoading="editLoading"
    >
      <car-loop-record-form ref="editRef" @editSuccess="editSuccess" @editError="editError"></car-loop-record-form>
    </a-modal>
    <!--详情Modal-->
    <a-modal :title="platNumber"
             :visible="detailDrawer"
             :width="1400"
             centered
             :footer="null"
             @cancel="detailClose">
      <loop-record-detail :dbDevicesData="dbDevicesData" ref="viewRef"></loop-record-detail>
    </a-modal>
  </page-layout>
</template>

<script>
  import entityCRUDWithCopy from '../../common/mixins/entityCRUDWithCopy'
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '@/api/service.url'
  import { carLoopRecordColumns, statusList } from './common/common'
  import LoopRecordDetail from './Detail'
  import CarLoopRecordForm from './Form'
  import mapDevices from '../../map/composite/mixins/map-devices'

  export default {
    name: 'List',
    mixins: [entityCRUDWithCopy, mapDevices],
    components: { CarLoopRecordForm, PageLayout, LoopRecordDetail },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.car.carLoopRecordApi,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          driverId: undefined,
          carInfoId: undefined,
          startTime: '',
          endTime: ''
        },
        initColumns: carLoopRecordColumns(),
        statusList: statusList(),
        orgTree: [],
        userList: [], //下发/制定人下拉数据
        carList: [],
        valueStyle: {
          color: '',
          textAlign: 'center'
        },
        statisticsData: {},
        dateSearch: null, //日期绑定数据
        platNumber: '', //车牌号
        executeUserStr: undefined,
        detailDrawer: false,
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
        }
      }
    },
    created () {
      this.getOrgTree()
      this.loadUserList()
      this.loadCarInfoList()
    },
    methods: {
      loadCarInfoList () {
        this.$http(this, {
          url: SERVICE_URLS.car.carInfoApi.select,
          noTips: true,
          success: (data) => {
            this.carList = data.body
          }
        })
      },
      loadUserList () {
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: 'car_',
            chooseType: 'RADIO'
          },
          noTips: true,
          success: (data) => {
            this.userList = data.body
          }
        })
      },
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          }
        })
      },
      rowClick (record) {
        return {
          on: {
            click: () => {
              this.platNumber = '车牌号：' + record.carInfo.platNumber
              this.handleView(record.id)
            }
          }
        }
      },
      detailClose () {
        this.detailDrawer = false
      },
      //日期选择
      dateOnChange (date, dateString) {
        this.searchParams.startTime = dateString[0]
        this.searchParams.endTime = dateString[1]
        this.search()
      },
      /*重置*/
      resetForm () {
        this.executeUserStr = undefined
        this.dateSearch = null
        this.searchParams.startTime = ''
        this.searchParams.endTime = ''
        Object.assign(this.searchParams, this.initParams)
        this.search()
      },
      beforeSearch () {
        this.searchParams.driverId = this.executeUserStr ? this.executeUserStr.split('_')[2] : undefined
        this.search()
      },
      selectStatus () {
        this.search()
      },
      //重写search和tableChange方法实现列表车辆任务名称Tag展示
      search () {
        this.$http(this, {
          url: this.entityBaseUrl.search,
          noTips: true,
          loading: 'tableLoading',
          data: this.searchParams,
          success: (data) => {
            this.tableData = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = data.body.number + 1
            this.pagination.pageSize = data.body.size
            this.searchParams.size = data.body.size
            this.searchParams.number = data.body.number
            for (let item of this.tableData) {
              item.recordNames = eval(item.recordNames)
            }
            this.pagination.total = data.body.totalElements
          }
        })
      },
      tableChange (pagination, filters, sorter) {
        this.searchParams.size = pagination.pageSize
        this.searchParams.number = pagination.current - 1
        if (sorter.order === 'ascend') {
          this.searchParams.direction = 'ASC'
        } else {
          this.searchParams.direction = 'DESC'
        }
        this.searchParams.order = sorter.field
        this.search()
      },
      exportWord () {
        const fileName = '清运记录'
        this.handExportWord(fileName)
      }
    }
  }
</script>

<style scoped></style>
